function notifica(title, message, url) {

    if (!Notification) {
        alert('Desktop notifications not available in your browser. Try Chromium.');
        return;
    }

    if (Notification.permission !== "granted")
        Notification.requestPermission();
    else {
        var notification = new Notification(title, {
            icon: document.location.origin+'/softcomshop/public/img/logo.png',
            body: message
        });

        notification.onclick = function () {
            window.open(url);
        };
    }
}

function mensagem (titulo, mensagem, tipo) {

    toastr.options = {
        closeButton: true,
        progressBar: true,
        positionClass: "toast-top-center",
        showMethod: 'slideDown',
        preventDuplicates: true,
        timeOut: 5000
    };

    switch (tipo){
        case "danger" :
            if (!titulo) { titulo = 'Erro';}
            toastr.error(mensagem, titulo);
            break;
        case "warning" :
            if (!titulo) { titulo = 'Atenção';}
            toastr.warning(mensagem, titulo);
            break;
        case "info" :
            if (!titulo) { titulo = 'Informativo';}
            toastr.info(mensagem, titulo);
            break;
        default :
            if (!titulo) { titulo = 'Sucesso';}
            toastr.success(mensagem, titulo);
            break;
    }
}

function softcomConfirmDelete($confirmCallback) {
    swal({
            title: "Você realmente deseja excluir essas informações?",
            text: "Não será possível recuperá-los depois!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim, pode excluir!",
            cancelButtonText: "Não, desejo cancelar!",
            closeOnConfirm: true,
            closeOnCancel: true },
        function(isConfirm){
            if (isConfirm) {
                $confirmCallback();
            }else {
                mensagem("Ação cancelada!", "Não se preocupe, nada foi excluído.", "info");
                return false;
            }
        }
    );
}

//$element deve ser um seletor jquery e deve conter o attribute href
function softcomSingleDelete($element){
    href = $element.attr('href');
    $element.click(function(event){
        event.preventDefault();
        softcomConfirmDelete(function(){
            window.location = href;
        });
    });
}

function softcomRowDelete(selector, messageSuccess, calback){
    $(selector).click(function(){
        var $tr  = $(this).parents('tr');
        var link = $(this).attr('href');

        softcomConfirmDelete(function(){
            $.get(link, function(result){
                if(result) {
                    $tr.remove();
                    mensagem("Sucesso", messageSuccess);

                    if (calback){
                        calback();
                    }
                }
            });
            return false;
        });
        return false;
    });
}

function populaCep($data, seletor)
{
    $(seletor+' #endereco').val($data.endereco);
    $(seletor+' #bairro').val($data.bairro);
    $(seletor+' #auto_cidade_id').val($data.cidade_uf);
    $(seletor+' #cidade_id').val($data.cidade_id);
}

function formBanco(banco, seletor)
{
    switch(banco)
    {
        case '1': //Brasil
            formDefault();
            $('#conta-label').html('<label for="conta" class="required">Cód Cedente</label>');
            break;
        case '237': //Bradesco
            formDefault();
            $('#conta-label').html('<label for="conta" class="required">Cód Cedente</label>');
            break;
        case '104': //Caixa
            formDefault();
            $('#agencia_dv-label').parent('div').hide();
            $('#conta-label').html('<label for="conta" class="required">Cód Cedente</label>');
            break;
        case '341': //Itau
            formDefault();
            break;
        case '33': //Santander
            formDefault();
            break;
    }

    setCarteira(Number(banco));
}

function setCarteira(banco){

    var carteiras = [""];
    switch(banco)
    {
        case 1: //Brasil
            var carteiras = ["11", "12", "16", "17", "18", "21","31", "51"];
            break;
        case 237: //Bradesco
            var carteiras = ["3", "6", "9", "19"];
            break;
        case 104: //Caixa
            var carteiras = ["1", "2"];
            break;
        case 341: //Itau
            var carteiras = ["148", "149", "153", "108", "180", "121", "150", "109", "191", "116", "117", "119",
                "134", "135", "136", "104", "188", "147", "112", "115", "177", "172", "107", "204",
                "205", "206", "173", "196", "103", "102", "174", "198", "167", "202", "203", "175"];
            break;
        case 33: //Santander
            var carteiras = ["101", "102", "201"];
            break;
    }

    carteiras.sort();

    var options = '<option value="">[selecione]</option>';

    $.each(carteiras, function(value, key){
        if(value == carteira){
            options += '<option value="' + key + '" selected="selected" >' + key + '</option>';
        } else {
            options += '<option value="' + key + '">' + key + '</option>';
        }
    });

    $("#carteira").html(options);
}

function formDefault(){
    $div = $('#agencia_dv-label').parent('div').show();
    $div = $('#conta-label').html('<label for="conta" class="required">Conta</label>');
}

function maskElements(elements) {
    $.each(elements, function(i, value){
        $element = $(value);
        reverse  = false;

        if (typeof $element.data('mask-reverse') !== 'undefined') {
            reverse = $element.data('mask-reverse');
        }

        if (typeof $element.data('mask') !== 'undefined') {
            $element.mask($element.data('mask'),{reverse:reverse});
        }
    });
}

function addHelpBlock(formId, elementId, message)
{
    $element = $('#'+formId + ' #'+elementId);
    removeHelpBlock($element);
    var helpClass = 'help-block';

    var html = '<span class="'+ helpClass + '">'+
        '<span class="glyphicon glyphicon-warning-sign"></span>'+
        message +'</span>';

    if(elementId.search('auto_') === 0){
        elementId = elementId.replace('auto_', '');
    }

    $('#'+formId + ' #'+elementId).parents('.form-group').addClass('has-error');
    $('#'+formId + ' div#div_'+elementId).append(html);
}

function removeHelpBlock(element)
{
    var id = $(element).attr('id');

    if(id.search('auto_') === 0){
        id = id.replace('auto_', '');
    }

    var $divElement = $('div#div_'+id);

    $divElement.parents('.form-group').removeClass('has-error');
    $divElement.find('.help-block').fadeOut();
}

function cidadeAutoComplete(formId, url) {
    $('#' + formId + ' #auto_cidade_id').typeahead({
        minLength: 2,
        dynamic: true,
        delay: 500,
        order: "asc",
        hint: true,
        display: "cidade_uf",
        source: {
            results: {
                url: [
                    {
                        type: "POST",
                        url: url + "/softcomtecnologia/autocomplete",
                        data: {
                            q: "@{{query}}",
                            t: "nfe_cidade",
                            label: "cidade_uf"
                        }
                    },
                    "data.results"
                ]
            }
        },
        callback: {
            onClick: function (node, a, item, event) {
                $('#' + formId + ' #cidade_id').val(item.id)
            },
            onResult: function (node, query, result, resultCount) {
                if (resultCount === 0) {
                    $('#' + formId + ' #cidade_id').val('')
                }
            }
        }
    });
}

function softcomAlert(title, message) {
    swal({
        title: title,
        text: message,
        type: 'warning',
        confirmButtonColor: '#f8ac59'
    });
}

function clearForm($element, $notCleanFields)
{
    $element.find('input').each(function() {
        if ($notCleanFields != 'undefined' && $.inArray($(this).attr('name'), $notCleanFields) < 0) {
            $(this).val('');
        }
    });
}

function formatMoney($fiels, points)
{
    var n = $fiels,
        c = points ? points : 2,
        d = ",",
        t = ".",
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j;

        j = (j = i.length) > 3 ? j % 3 : 0;

    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}

function fcnpj(v) {
    v = v.replace(/\D/g, "")                           //Remove tudo o que n�o � d�gito
    v = v.replace(/^(\d{2})(\d)/, "$1.$2")             //Coloca ponto entre o segundo e o terceiro d�gitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto d�gitos
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")           //Coloca uma barra entre o oitavo e o nono d�gitos
    v = v.replace(/(\d{4})(\d)/, "$1-$2")              //Coloca um h�fen depois do bloco de quatro d�gitos
    return v
}

function fcpf(v) {
    v = v.replace(/\D/g, "")                    //Remove tudo o que n�o � d�gito
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto d�gitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto d�gitos
    //de novo (para o segundo bloco de n�meros)
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um h�fen entre o terceiro e o quarto d�gitos
    return v
}

function softcomConfirm(title, text, type, textConfirm, $callback)
{
    swal({
        title: title,
        text: text,
        type: type,
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: textConfirm,
        cancelButtonText: "Não!",
        closeOnConfirm: false,
        html: true
    }, function () {
        $callback();
    });
}