'use strict';

function SoftcomTecnologia() {
    var self            = this,
        _myElement      = undefined,
        _method         = 'GET',
        _formId,
        _makeObjects    = {
            mask: SoftcomMask,
            affix: SoftcomAffix,
            modal: SoftcomModal,
            assets: SoftcomAssets,
            event_modal: EventModalLaravel,
            btn_choice: SoftcomBtnChoice
        };

    SoftcomTecnologia.prototype.formId = function(formId)
    {
        if (formId === undefined) {
            return _formId;
        }

        _formId = formId;

        return this;
    };


    SoftcomTecnologia.prototype.method = function (method)
    {
        if (method === undefined) {
            return _method;
        }

        _method = method;

        return this;
    };


    SoftcomTecnologia.prototype.myElement = function(seletor)
    {
        if (seletor === undefined) {
            return _myElement;
        }

        _myElement = $(seletor);

        return this;
    };


    SoftcomTecnologia.prototype.overlay = function(html, remover)
    {
        var $body = $('body'),
            $overlay = $('#laravel-overlay');

        remover = remover == undefined ? false : remover;
        html    = html == undefined
            ? '<div class="text-center">' +
            '<div class="sk-spinner sk-spinner-double-bounce">' +
            '<div class="sk-double-bounce1" style="background: none;">' +
            '<div class="fa fa-folder-open-o orange fa-4x"></div>' +
            '</div>' +
            '<div class="sk-double-bounce2" style="background: none;">' +
            '<div class="fa fa-folder-open-o orange fa-4x"></div>' +
            '</div>' +
            '</div>' +
            '<p class="m-t-md">Aguarde...</p>' +
            '</div>'
            : html;

        if (remover || html === true) {
            $overlay.fadeOut(500, function () {
                $(this).remove();
            });

            return this;
        }

        if ($body.find('#laravel-overlay').length == 0) {
            $body.append('<div id="laravel-overlay" class="laravel-overlay">' + html + '</div>');
            $overlay.fadeIn(250);
        }

        return this;
    };


    SoftcomTecnologia.prototype.softAjax = function(action, params, callback, ajaxCallbacks)
    {
        var that            = this,
            oldOptions      = $.ajaxSetup(),
            mensagemSoftcomException  = function (response) {
                if (typeof response != 'object' || !response.softcom_exception) {
                    return false;
                }

                mensagem(
                    response.softcom_exception.title,
                    response.softcom_exception.message,
                    response.softcom_exception.level
                );

                return true;
            },
            isAcessoNegado  = function (response) {
                if (typeof response == 'string' && response.search("<h1>Acesso negado</h1>") > 0) {
                    mensagem('Erro', 'A página acessada não pode ser exibida devido o usuário não ter permissão para acessa-lá', 'danger');
                    return false;
                }
                return true;
            },
            options         = {
                type: that.method(),
                url: action,
                data: params,
                success: function(response, textStatus, jqXHR) {
                    isAcessoNegado(response);
                    mensagemSoftcomException(response);
                    console.log('SoftcomTecnologia::softAjax', 'requisição ajax realizada com sucesso!')
                },
                error: function (response, textStatus, errorThrown) {
                    if (mensagemSoftcomException(response.responseJSON)) {
                        return true;
                    }

                    if (undefined !== oldOptions['error']) {
                        oldOptions['error'](response, textStatus);
                    }

                    if (response.responseText !== 'undefined') {
                        var erros  = JSON.parse(response.responseText),
                            formId = that.formId();

                        if (formId === '') {
                            formId = jQuery.prototype.isPrototypeOf(that.myElement())
                                ? that.myElement().parents('form').attr('id')
                                : undefined;
                        }
                        if (formId === undefined) {
                            formId = 'body';
                        }

                        $.each(erros, function(id, value) {
                            that.addHelpBlock(formId, '#' + id, value);
                            mensagem('Alerta', 'Preencha corretamente os campos', 'warning');
                        });
                    }
                }
            };

        if (action === undefined) {
            console.log('SoftcomTecnologia::softAjax: SyntaxError', 'action não foi informada');
            return false;
        }

        if (typeof callback == 'function') {
            options.success = function (response) {
                if (!isAcessoNegado(response)) {
                    return false;
                }
                mensagemSoftcomException(response);

                var result = callback(response);

                if (result === undefined) {
                    return true;
                }

                return result;
            };
        }


        if (typeof ajaxCallbacks == 'function') {
            var myError = ajaxCallbacks;

            if (ajaxCallbacks['error'] != undefined) {
                myError = ajaxCallbacks['error'];
            }

            options.error = function(response){
                if (mensagemSoftcomException(response.responseJSON)) {
                    return true;
                }

                var result = myError(response);

                if (result === undefined) {
                    return false;
                }

                return result;
            };
        }

        if (typeof ajaxCallbacks == 'object') {
            if (undefined != ajaxCallbacks['beforeSend']) {
                options.beforeSend = function (jqXHR, settings) {
                    if (undefined !== oldOptions['beforeSend']) {
                        oldOptions['beforeSend'](jqXHR, settings);
                    }
                    ajaxCallbacks['beforeSend'](jqXHR, settings);
                };
            }

            if (undefined != ajaxCallbacks['complete']) {
                options.complete = function (jqXHR, textStatus) {
                    if (undefined !== oldOptions['complete']) {
                        oldOptions['complete'](jqXHR, textStatus);
                    }
                    ajaxCallbacks['complete'](jqXHR, textStatus);
                };
            }

            if (undefined != ajaxCallbacks['error']) {
                options.error = function (jqXHR, textStatus, errorThrown) {
                    if (mensagemSoftcomException(jqXHR.responseJSON)) {
                        return true;
                    }
                    if (undefined !== oldOptions['error']) {
                        oldOptions['error'](jqXHR, textStatus, errorThrown);
                    }
                    ajaxCallbacks['error'](jqXHR, textStatus, errorThrown);
                };
            }
        }
        $.ajax(options);
    };


    SoftcomTecnologia.prototype.addHelpBlock = function(formId, elementId, mensagemDeError)
    {
        if (formId === undefined && elementId === undefined) {
            console.log('SoftcomTecnologia::addHelpBlock: SyntaxError', 'o id do form não foi informado');
            return false;
        }

        var backgroundHelpBlock = "";

        if ($(elementId).parents('.modal-body').css("background-color") != undefined) {
            backgroundHelpBlock = 'background-color:'+ $('.modal-body').css("background-color");
        }

        this.removeHelpBlock(formId, elementId);

        var element     = $(formId + (elementId !== undefined ? ' ' + elementId : '')),
            helpClass   = 'help-block',
            html        = '<span class="' + helpClass + '" style="'+backgroundHelpBlock+'"><span class="glyphicon glyphicon-warning-sign"></span> ' +
                mensagemDeError + '</span>';

        element.parents('.form-group')
            .addClass('has-error')
            .append(html);
    };


    SoftcomTecnologia.prototype.removeHelpBlock = function(formId, elementId)
    {
        if (formId === undefined && elementId === undefined) {
            console.log('SoftcomTecnologia::removeHelpBlock: SyntaxError', 'o id do form não foi informado');
            return false;
        }

        elementId = elementId === undefined ? formId : elementId;

        var removeBlock = function (seletor) {
            $(seletor).parents('.form-group')
                .removeClass('has-error')
                .find('.help-block')
                .fadeOut()
                .remove();
        };

        removeBlock(elementId);
        $(elementId).on('focus', function(){
            removeBlock(this);
        });
    };


    SoftcomTecnologia.prototype.iniciar = function()
    {
        var datepicker = $.fn.datepicker;
        $.fn.bootstrapDP = datepicker;

        $('.datepicker').bootstrapDP({
            format: 'dd/mm/yyyy',
            language: 'pt-BR',
            todayBtn: 'linked',
            autoclose: true
        });
        $('.btn-datepicker').on('click', function() {
            $(this).parents('.input-group').find('.datepicker').trigger('focus');
        });
        $('[data-toggle="tooltip"]').tooltip();
        $('.btn-datetimepicker').on('click', function() {
            $(this).parents('.input-group').find('.datetimepicker').trigger('focus');
        });
    };


    SoftcomTecnologia.prototype.masks = function (seletor, maskType)
    {
        if (undefined === seletor) {
            console.log('SoftcomTecnologia::masks: SyntaxError', 'o parâmetro seletor não foi informado');
        }
        if (undefined === maskType) {
            console.log('SoftcomTecnologia::masks: SyntaxError', 'o parâmetro mskType não foi informado');
        }

        var softcomMask = new SoftcomMask(seletor);

        switch (maskType.toLowerCase()) {
            case 'phone':
                softcomMask.phone();
                break;
            case 'maiuscula':
                softcomMask.maiuscula(seletor);
                break;
            case 'cpfcnpj':
                softcomMask.cpfcnpj();
                break;
            default :
                console.log('SoftcomTecnologia::masks: Error', 'o maskType \'' + maskType + '\' não foi informado');
        }

        return this;
    };


    SoftcomTecnologia.prototype.make = function(object)
    {
        return self.makeFactory(_makeObjects, arguments);
    };

    SoftcomTecnologia.prototype.changeIndexBreadcrumb = function (key, values) {

        try {
            key = !Array.isArray(key) ? [key] : key;
            values = !Array.isArray(values) ? [values] : values;

            $('.breadcrumb li').each(function (index, element) {
                element = $(element);
                key.forEach(function (valor, posicao) {
                    if (element.text() == valor) {
                        element.text(values.length == 1 ? values[0] : values[posicao]);
                    }
                });
            });
        } catch (e) {
            console.log("Algo de errado aconteceu ao tentar mudar os valores do breadcrumb.");
        }
    };

    this.addMake = function (key, object) {
        if (typeof object !== 'function' || key == undefined || _makeObjects[key] !== undefined) {
            return false;
        }

        _makeObjects[key] = object;
        return true;
    };


    this.makeFactory = function (listOfClass, argument) {

        if (typeof listOfClass != 'object') {
            return;
        }

        var make = listOfClass[argument[0].toLowerCase()];

        if (undefined === make) {
            console.log('SoftcomTecnologia::makeFactory:', 'a classe ' + argument[0] + ' não foi encontrada');
            return false;
        }

        return new (Function.prototype.bind.apply(make, argument));
    };


    this.ajaxSetup = function ()
    {
        var adicionandoLoadAjax = function () {
                $('#loading').fadeIn('slow');
                $('.nav-tabs a').attr('class', 'disabled');
                self.overlay();
            },
            removendoLoadAjax   = function () {
                $('#loading').fadeOut('fast');
                $('.nav-tabs a').removeAttr('class');
                self.overlay(true);
            },
            options             = {
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                beforeSend: adicionandoLoadAjax,
                complete: removendoLoadAjax,
                error: removendoLoadAjax
            };

        $.ajaxSetup(options);

        return this;
    };


    this.callQueueAjax = function (modulo) {
        var that = this,
            uri = '/softcomtecnologia/queue-ajax/',
            baseUrl,
            ajaxSuccess = function (response) {
                if (undefined == response.status) {
                    console.log('SoftcomTecnologia::callQueueAjax', 'Erro ao receber a resposta!');
                    return true;
                }

                console.log('SoftcomTecnologia::callQueueAjax', response.message);
            },
            onEvent = function () {
                baseUrl = $('#baseUrl').data('value');
                that.softAjax(baseUrl + uri + modulo, [], ajaxSuccess);
            };

        $(document).ready(onEvent);

        return that;
    };
}

function SoftcomMask (seletor) {
    this.seletor = seletor;

    SoftcomMask.prototype.cpfcnpj = function (seletor) {
        this.seletor = undefined == seletor ? this.seletor : seletor;

        var maskCpfCnpj = function (val) {
                var valLength = val.replace(/\D/gi, '').length;

                if (valLength > 11) {
                    return  '##.###.###/####-##';
                }
                if (valLength < 12) {
                    return  '###.###.###-###';
                }
            },
            cpfCnpjOptions = {
                placeholder: "000.000.000-00",
                onKeyPress: function (value, event, field, options) {
                    $(field).mask(maskCpfCnpj.apply({}, arguments), cpfCnpjOptions);
                },
                onFocus: function (value, event, field, options) {
                    $(field).mask(maskCpfCnpj.apply({}, arguments), cpfCnpjOptions);
                }
            };

        $(this.seletor).mask(maskCpfCnpj, cpfCnpjOptions);

        return this;
    };


    SoftcomMask.prototype.phone = function (seletor) {
        this.seletor = undefined == seletor ? this.seletor : seletor;

        var maskPhone = function (val) {
                var phonesTypes = [
                        '####-#####',
                        '#####-#####',
                        '(##) ####-#####',
                        '(##) #####-####',
                        '(##) #####-#####'
                    ],
                    digitosLength = val.replace(/\D/gi, '').length;

                if (digitosLength < 9) {
                    return phonesTypes[0];
                }
                if (digitosLength == 9) {
                    return phonesTypes[1];
                }
                if (digitosLength == 10) {
                    return phonesTypes[2];
                }
                if (digitosLength == 11) {
                    return phonesTypes[3];
                }
                if (digitosLength > 11) {
                    return phonesTypes[4];
                }
            },
            phoneOptions = {
                placeholder: "(00) 0000-0000",
                onKeyPress: function(value, event, field, options) {
                    $(field).mask(maskPhone.apply({}, arguments), phoneOptions);
                }
            };

        $(this.seletor).mask(maskPhone, phoneOptions);

        return this;
    };

    SoftcomMask.prototype.maiuscula = function (seletor) {
        var input = "#" + seletor,
            letraMaiuscula;

        if ( seletor instanceof Object){
            input = seletor.selector;
        }

        $(input).keyup(function() {
            letraMaiuscula = this.value.toUpperCase();
            this.value = letraMaiuscula;
        });

        return this;
    };
}

function SoftcomAssets(src, type, modulo, attr)
{
    var self    = this,
        init    = function () {
            getType(type);
            getSrc(src, modulo);

            if (undefined == self.element) {
                return '';
            }

            self.asset = document.createElement(self.element);
            self.asset.type = self.type;

            if (self.element == 'script') {
                self.asset.src = self.src;
            } else {
                self.asset.href = self.src;
                self.asset.rel  = 'stylesheet';
            }

            setAttr(attr);
            return document.head.appendChild(self.asset);
        },
        getType = function (type) {
            type = undefined == type ? 'script' : type;

            if (type == 'script' || type == 'javascript' || type == 'text/javascript') {
                self.element    = 'script';
                self.type       = 'text/javascript';
            }
            if (type == 'css' || type == 'style' || type == 'text/css') {
                self.element    = 'link';
                self.type       = 'text/css';
            }
        },
        getSrc  = function (src, modulo) {
            src = undefined == src ? '' : src;

            if (undefined !== modulo) {
                var base = $('#baseUrl').data('value') + '/packages/softcomtecnologia/';

                if (modulo === 'laravel') {
                    base += modulo + '/assets/laravel/' + src;
                } else {
                    base += modulo + '/' + src;
                }

                src = base;
            }

            self.src = src;
        },
        setAttr = function(attr){
            if (!(attr instanceof Object)) {
                return;
            }

            $.each(attr, function (index, value) {
                self.asset[index] = value;
            });
        };

    this.asset      = undefined;

    this.type       = undefined;
    this.src        = undefined;
    this.element    = undefined;
    this.attr       = undefined;


    return init();
}

function EventModalLaravel(options) {

    var self,
        $this,
        action,
        _seletor,
        _modal,
        _callbacks,
        _existsCallback,
        _getAction,
        _onAttachEvent,
        _onAttachEventAjaxSuccess;


    /**
     * @returns {EventModalLaravel}
     */
    this.eventAttach = function () {
        $('body').on('click', _seletor, _onAttachEvent);

        return this;
    };


    /**
     * @returns {EventModalLaravel}
     */
    this.eventDetach = function () {
        $('body').undelegate(_seletor, 'click');

        return this;
    };


    /**
     * Ex.:
     *      {string}   '.nome-do-seletor'
     *      {object}    {
     *                      seletor: '.nome-do-seletor',
     *                      eventAttach: function (response, modal, $this) {}
     *                  }
     * @param {string|object} options
     * @returns {EventModalLaravel}
     */
    this.setOptions = function (options) {
        if (options == undefined) {
            return this;
        }

        if (typeof options == 'string' || (options['seletor'] !== undefined && typeof options['seletor'] == 'string')) {
            _seletor =  options.seletor ? options.seletor : options;
        }

        if (typeof options !== 'object') {
            return this;
        }

        $.each(options, function (index, value) {
            if (_callbacks[index] !== undefined && typeof value == 'function') {
                _callbacks[index] = value;
            }
        });

        return this;
    };


    /**
     * @param {string|object} options
     * @returns {EventModalLaravel}
     */
    this.start = function (options) {
        self.setOptions(options);
        self.eventAttach();

        return self;
    };


    /**
     * @type {EventModalLaravel}
     */
    self = this;


    /**
     * @type {string}
     * @private
     */
    _seletor = '.event-modal-laravel';


    /**
     * @type {object} SoftcomModal
     * @private
     */
    _modal = softcomTecnologia.make('modal');


    /**
     * @type {object}
     * @private
     */
    _callbacks = {
        eventAttach: false
    };


    /**
     * @param {string} key
     * @returns {*|boolean}
     * @private
     */
    _existsCallback = function (key) {
        return _callbacks[key] && (typeof _callbacks[key] == 'function');
    };


    /**
     * @param $this
     * @returns {string}
     * @private
     */
    _getAction = function ($this) {
        if ($this.attr('href')) {
            action = $this.attr('href');
        }
        if ($this.data('href')) {
            action = $this.data('href');
        }
        if (!action) {
            throw new DOMException('This selector has no attribute for url [href or data-href].');
        }

        return action;
    };


    /**
     * @param event
     * @returns {boolean}
     * @private
     */
    _onAttachEvent = function (event) {
        event.preventDefault();
        $this = $(this);

        if (!_getAction($this)) {
            return false;
        }

        softcomTecnologia.method('GET').softAjax(action, [], _onAttachEventAjaxSuccess);
    };


    /**
     * @param response
     * @private
     */
    _onAttachEventAjaxSuccess = function (response) {
        if (_existsCallback('eventAttach')) {
            _callbacks.eventAttach(response, _modal, $this);
            return;
        }

        var title = $this.attr('data-title') ? $this.attr('data-title') : $this.attr('title');

        if (!title) {
            title = $this.attr('data-original-title');
        }

        _modal.titulo(title);
        _modal.conteudo(response.html !== undefined ? response.html : response);
        _modal.run(true);

        maskElements($(_modal.modalId).find('input, select, textarea'));
    };


    self.start(options);
}


function SoftcomAffix (element) {
    var defaultOptions  = {
        style: '',
        class: '',
        callback: {
            onReady: null,
            onAjax: null
        }
    };

    this.element = element;

    SoftcomAffix.prototype.init = function(options) {
        $(this.element).attr('style', options.style === undefined
            ? defaultOptions.style
            : options.style
        );

        $(this.element).addClass(options.class === undefined
            ? defaultOptions.class
            : options.class
        );

        $(document).ready(typeof  options.callback.onReady === "function"
            ? options.callback.onReady
            : defaultOptions.callback.onReady
        );

        $(document).ajaxComplete(typeof options.callback.onAjax === "function"
            ? options.callback.onAjax
            : defaultOptions.callback.onAjax
        );

        return this;
    };
}


function SoftcomModal()
{
    this.modalId    = '#content-plus';
    this.btnClose   = '#btn-modal-plus-close';

    var title       = '',
        content     = '',
        options     = {show: false};

    SoftcomModal.prototype.titulo = function (titulo)
    {
        if (undefined === titulo) {
            return title;
        }

        title = titulo;

        return this;
    };

    SoftcomModal.prototype.conteudo = function (conteudo)
    {
        if (undefined === conteudo) {
            return content;
        }

        content = conteudo;

        return this;
    };

    SoftcomModal.prototype.options = function (key, value) {
        if (typeof key == 'object') {
            options = key;

            return this;
        }

        options[key] = value;

        return this;
    };

    SoftcomModal.prototype.labelTitulo = function (enable)
    {
        var $labelTitulo = $('.modal-header .font-bold');

        enable = undefined === enable ? true : enable;

        $labelTitulo.show();

        if (!enable) {
            $labelTitulo.hide();
        }

        return this;
    };

    SoftcomModal.prototype.run = function (open)
    {
        this.options('show', undefined === open ? false : open);

        if (title != '') {
            $('.modal-header h2').html(title);
        }

        if (content != '') {
            $('.modal-body').html(content);
        }

        $(this.modalId).modal(options);

        return this;
    };

    SoftcomModal.prototype.rodape = function (enable, texto)
    {
        var $footer = $('.modal-footer');

        enable = undefined === enable ? true : enable;

        $footer.show();

        if (!enable) {
            $footer.hide();
        }

        $footer.html(undefined === texto ? '' : texto);

        return this;
    };

    SoftcomModal.prototype.close = function () {
        $(this.btnClose).trigger('click');

        return this;
    }
}


function SoftcomBtnChoice(title, choices) {
    var self = this,
        _events = {
            pluginCallback: function () {},
            afterChoices: function (seletor) {}
        },
        _setOption = function (key, value) {
            if (!key || !value) {
                return;
            }

            self.optionsConfirm[key] = value;
        },
        __construct = function (title, choices) {
            $(document).ready(function () {
                self.attach(title, choices);
            });
        };

    this.class = '.btn-choice';

    this.optionsConfirm = {
        title: '',
        text: '',
        type: 'info',
        showConfirmButton: false,
        showCancelButton: true,
        closeOnConfirm: false,
        closeOnCancel: true,
        cancelButtonText: 'Fechar',
        html: true
    };

    this.eventPlugin = function (callback) {
        if (typeof callback == 'function') {
            _events.pluginCallback = callback;
        }

        return this;
    };

    this.eventAfterChoices = function (callback) {
        if (typeof callback == 'function') {
            _events.afterChoices = callback;
        }

        return this;
    };

    this.setTitle = function (value) {
        _setOption('title', value);

        return this;
    };

    this.setContent = function (value) {
        this.setChoices(value);

        return this;
    };

    this.setChoices = function (value) {
        if (typeof value == 'object' && value instanceof  jQuery) {
            value = value.html();
        }

        _setOption('text', value ? this.optionsConfirm['text'] + value : false);

        return this;
    };

    this.resetContent = function () {
        this.optionsConfirm['text'] = '';

        return this;
    };

    this.attach = function (title, choices) {
        var $this,
            onClick = function (event) {
                event.preventDefault();

                $this = $(this);
                swal(self.optionsConfirm, _events.pluginCallback());
                _events.afterChoices($this);
            };

        this.setTitle(title)
            .setChoices(choices);

        $('body')
            .undelegate(this.class, 'click')
            .on('click', this.class, onClick);
    };

    __construct(title, choices);
}



/**
 * Função responsavel por consultar se existe registro cadastrado.
 *
 * @param $seletor      seletor JQuery
 * @param table         string da tabela a ser consultada
 * @param url           url base.
 * @param id            id do registro, parametro facultativo, deve ser usado caso deseje considerar na consulta,
 *                       eg(todos que nao seja o id do registro corrente).
 * @param message       mensagem a ser exibida, parametro facultativo pode ser personalizada.
 * @param field         nome do campo da tabela, parametro facultativo.
 * @param where
 */
var checkDuplicateRecordTimeOut = null;
var checkDuplicateRecordMyXHR = null;
function checkDuplicateRecord($seletor, table, url, id, message, field, where) {
    $seletor.on('keyup paste', function (event) {

        setTimeout(function () {

            var value = $seletor.val().replace(/[^0-9]/g, '');

            if (field === undefined) {
                field = $seletor.attr('name');
            }

            if (message === undefined) {
                message = ' O campo já encontra-se cadastrado.';
            }

            var action = url + '/softcomtecnologia/check-duplicate-record',
                $form = $seletor.parents('form'),
                data = {
                    'table': table,
                    'field': field,
                    'value': value
                };

            if (id !== undefined) {
                data.id = id;
            }

            if (where !== undefined) {
                data.where = where;
            }

            if (value.length == 11 || value.length == 12 || value.length == 14 || event.keyCode == undefined) {
                if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)
                    || event.keyCode == undefined || event.keyCode == 8) {
                    if (checkDuplicateRecordTimeOut)
                        clearTimeout(checkDuplicateRecordTimeOut);

                    checkDuplicateRecordTimeOut = setTimeout(function () {
                        // Cancel the last request if valid
                        if (checkDuplicateRecordMyXHR) {
                            checkDuplicateRecordMyXHR.abort();
                        }

                        checkDuplicateRecordMyXHR = $.get(action, data, function (response) {
                            if (response == 'true') {
                                addHelpBlock($form.attr('id'), $seletor.attr('id'), message);
                            } else {
                                removeHelpBlock($seletor);
                            }
                        });

                    }, 700);
                }
            }
        }, 300);
    });
}

var softcomTecnologia = new SoftcomTecnologia();

$(document)
    .ready(softcomTecnologia.iniciar)
    .ajaxComplete(softcomTecnologia.iniciar);
