/**
 * Editor
 *
 * Para personalizar os eventos deste plugin pode ser inserido um objeto com as opções a seguir descritas:
 *
 *
 * Caso possua mais de uma listagem com editor ou queira definir o evento com delegate,
 * segue abaixo exemplo para personalizar:
 *
 * ```js
 *  seletorEventoSave: '#nome-do-seu-seletor.jquery'
 * ```
 *
 *  Exemplo de como personalizar os seletores
 *
 * ```js
 *      classes: {
 *          salvar: '.salvar-linha',
 *          editar: '.editar',
 *          cancelar: '.cancelar-linha',
 *          remover: '.remover'
 *      }
 * ```
 *
 *  Exemplo de como adicionar callbacks
 *
 * ```js
 *      salvarCallbacks: {
 *         complete: function (response, editor, $tr) {},
 *         error: function (response, editor, $tr) {}
 *      },
 *
 *      cancelarCallbacks: {
 *          complete: function (response, editor, $tr) {},
 *          error: function (response, editor, $tr) {}
 *      }
 *
 *      removerCallbacks: {
 *          complete: function (response, editor, $tr) {},
 *          error: function (response, editor, $tr) {}
 *      }
 *  ```
 */
(function( $ ){
    $.widget( "table.editor", {
        _create: function() {
            var self                = this,
                $element            = self.element,
                options             = self.options,
                seletorEventoSalvar = undefined === options.seletorEventoSave ? document : options.seletorEventoSave,
                classes             = self._preparandoOptionsClasses(options),
                callbacks           = self._preparandoOptionsCallbacks(options);

            self._eventoEditar(self, $element, classes);
            self._eventoCancelar(self, $element, classes, callbacks);
            self._eventoRemover(self, $element, classes, callbacks);
            self._eventoSalvar(self, seletorEventoSalvar, classes, callbacks);
        },

        _eventoCancelar: function (self, $element, classes, callbacks) {
            $element.on('click', classes.cancelar, function(event) {
                event.preventDefault();

                var $this   = $(this),
                    $tr     = $(this).parents('tr'),
                    action  = $this.data('href') + $tr.find('#id').val(),
                    success = function (response) {
                        if (typeof callbacks.cancelarComplete == 'function') {
                            callbacks.cancelarComplete(response, self, $tr);

                            return true;
                        }

                        $tr.html($(response).find('tbody td'));
                        self._removeStyleEdicao($tr);
                    },
                    error   = function (response) {
                        if (typeof callbacks.cancelarError == 'function') {
                            callbacks.cancelarError(response, self, $tr);

                            return true;
                        }
                        self._removeStyleEdicao($element.parents('tr'));
                    };

                $tr.parents('table').find('a').removeAttr('disabled');
                softcomTecnologia
                    .method('GET')
                    .softAjax(action, [], success, error);
            });
        },

        _eventoEditar: function (self, $element, classes) {
            $element
                .undelegate(classes.editar, 'click')
                .on('click', classes.editar, function (event) {
                    event.preventDefault();

                    self._travarItens($(this));

                    var id          = $(this).data('id'),
                        action      = $(this).attr('href'),
                        $tr         = $(this).parents('tr'),
                        success     = function (response) {
                            $tr.html(response);

                            try {
                                maskElements($tr.find($(':input')));
                            } catch(err) {}
                        };

                    softcomTecnologia
                        .method('POST')
                        .softAjax(action, {id:id}, success);

                    return false;
                });
        },

        _eventoRemoverSuccess: function (response, self, $tr) {
            mensagem('Sucesso', 'Você excluiu um Item!');
            $tr.remove();
        },

        _eventoRemover: function (self, $element, classes, callbacks) {
            $element
                .undelegate(classes.remover, 'click')
                .on('click', classes.remover, function(event) {
                    event.preventDefault();

                    var action      = $(this).attr('href'),
                        id          = $(this).data('id'),
                        $tr         = $(this).parents('tr'),
                        optionsSwal = {
                            title: "Você realmente deseja excluir este item?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Sim, pode excluir!",
                            cancelButtonText: "Não, desejo cancelar!",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        confirmSwal = function(isConfirm){
                            if (!isConfirm) {
                                mensagem("Ação cancelada!", "Não se preocupe, nada foi feito.", "info");

                                return false;
                            }
                            if (action == undefined){
                                if (typeof callbacks.removerComplete == 'function') {
                                    callbacks.removerComplete(null, self, $tr);

                                    return true;
                                }
                                self._eventoRemoverSuccess(null, self, $tr);
                                return true;
                            }

                            softcomTecnologia
                                .method('POST')
                                .softAjax(action, {id:id}, successAjax);
                        },
                        successAjax = function(response){
                            if (typeof callbacks.removerComplete == 'function') {
                                callbacks.removerComplete(response, self, $tr);

                                return true;
                            }

                            if (response > 0) {
                                self._eventoRemoverSuccess(response, self, $tr);

                                return true;
                            }

                            mensagem('Erro', 'Algo errado aconteceu, não conseguimos excluir o Item!', 'danger');
                        };

                    swal(optionsSwal, confirmSwal);

                    return false;
                });
        },

        _eventoSalvarSuccess: function(response, self, $tr) {
            self._decoraLinha($tr, 'sucesso');
            mensagem('Sucesso', 'Você editou um Item!');
            $tr.html($(response).find('tbody td'));
        },

        _eventoSalvar: function (self, seletorEventoSalvar, classes, callbacks) {
            $(seletorEventoSalvar)
                .undelegate(classes.salvar, 'click')
                .on('click', classes.salvar, function(event) {
                    event.preventDefault();

                    var action      = $(this).data('href'),
                        $tr         = $(this).parents('tr'),
                        form        = $tr.find('input, select'),
                        successAjax = function (response) {

                            if (typeof callbacks.salvarComplete == 'function') {
                                callbacks.salvarComplete(response, self, $tr);

                                return true;
                            }

                            self._eventoSalvarSuccess(response, self, $tr);
                        },
                        errorAjax = function (response) {
                            if (typeof callbacks.salvarError == 'function') {
                                callbacks.salvarError(response);
                                self._decoraLinha($tr, 'alerta');

                                return true;
                            }
                            var errors = undefined !== response.responseJSON ? response.responseJSON : false;

                            if (!errors) {
                                mensagem('Aviso', 'Algo errado aconteceu, não conseguimos editar o item!', 'warning');
                                self._decoraLinha($tr, 'erro');

                                return false;
                            }

                            mensagem('Aviso', 'Verifique os dados e tente novamente!', 'warning');
                            self._decoraLinha($tr, 'alerta');

                            $.each(errors, function(i, message) {
                                self._addHelpBlockByElement($tr, i, message);
                            });
                        };

                    softcomTecnologia
                        .method('POST')
                        .softAjax(action, form.serialize(), successAjax, errorAjax);
                });
        },

        _preparandoOptionsClasses: function (options) {
            return {
                editar: undefined !== options.classes && undefined !== options.classes.editar
                    ? options.classes.editar
                    : '.editar',

                cancelar: undefined !== options.classes && undefined !== options.classes.cancelar
                    ? options.classes.cancelar
                    : '.cancelar-linha',

                salvar: undefined !== options.classes && undefined !== options.classes.salvar
                    ? options.classes.salvar
                    : '.salvar-linha',

                remover: undefined !== options.classes && undefined !== options.classes.remover
                    ? options.classes.remover
                    : '.remover'
            };
        },

        _preparandoOptionsCallbacks: function (options) {
            return {
                salvarComplete: undefined !== options.salvarCallbacks && undefined !== options.salvarCallbacks.complete
                    ? options.salvarCallbacks.complete
                    : false,

                salvarError: undefined !== options.salvarCallbacks && undefined !== options.salvarCallbacks.error
                    ? options.salvarCallbacks.error
                    : false,

                removerComplete: undefined !== options.removerCallbacks && undefined !== options.removerCallbacks.complete
                    ? options.removerCallbacks.complete
                    : false,

                removerError: undefined !== options.removerCallbacks && undefined !== options.removerCallbacks.error
                    ? options.removerCallbacks.error
                    : false,

                cancelarComplete: undefined !== options.cancelarCallbacks && undefined !== options.cancelarCallbacks.complete
                    ? options.cancelarCallbacks.complete
                    : false,

                cancelarError: undefined !== options.cancelarCallbacks && undefined !== options.cancelarCallbacks.error
                    ? options.cancelarCallbacks.error
                    : false
            };
        },

        _destravarItens: function($element)
        {
            //Desabilita botoes na tabela
            $element.parents('table').find('a').removeAttr('disabled');

            var id = $element.parents('tr').find('#id').val();

            $.get($element.data('href')+id, function(result){
                $element.parents('tr').html($(result).find('tbody td'));
            });

            this._removeStyleEdicao($element.parents('tr'));
        },

        _decoraLinha: function($element, tipo)
        {
            if (typeof tipo === 'undefined') {
                tipo = 'sucesso';
            }

            tipo = 'cor-' + tipo + ' animated flipInX';
            $element.addClass(tipo);

            setTimeout(function () {
                $element.removeClass(tipo);
            }, 400);

            this._destravarItens($element);
        },

        _travarItens: function($element)
        {
            //Desabilita botoes da tabela
            $element.parents('table').find('a').attr('disabled', true);
        },

        _removeStyleEdicao: function($element)
        {
            //Adiciona background na linha da tabela
            $element.attr('style', 'background: #fff!important;border-left:none;');
        },

        _maskElements: function (elements) {
            $.each(elements, function(i, value){
                $element = $(value);
                reverse  = false;

                if (typeof $element.data('mask-reverse') !== 'undefined') {
                    reverse = $element.data('mask-reverse');
                }

                if (typeof $element.data('mask') !== 'undefined') {
                    $element.mask($element.data('mask'),{reverse:reverse});
                }
            });
        },

        _addHelpBlockByElement: function ($selector, elementId, message)
        {
            this._removeHelpBlock($selector, elementId);

            var helpClass = 'help-block';
            var html = '<span class="'+ helpClass + '">'+
            '<span class="glyphicon glyphicon-warning-sign"></span>'+
            message +'</span>';

            if(elementId.search('auto_') === 0){
                elementId = elementId.replace('auto_', '');
            }

            $selector.find('#'+elementId).after(html);
            $selector.find('#'+elementId).parents('.form-group').addClass('has-error');
        },

        _removeHelpBlock: function ($selector, elementId)
        {
            var id = $selector.find('#'+elementId).attr('id');

            if(id.search('auto_') === 0){
                id = id.replace('auto_', '');
            }

            var $divElement = $('div#div_'+id);

            $divElement.parents('.form-group').removeClass('has-error');
            $divElement.find('.help-block').fadeOut();
        }
    });
})( jQuery );
