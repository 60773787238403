(function ($) {

    $.widget("ui.altabs", {
        _create: function () {
            this.a = this.element.find('ul li a');
            this._bindEvents();
        },

        _bindEvents: function () {
            var self = this;
            this.a.on('click', function () {

                self._parentIdExists(this);
                var data = $(this).data();

                if (data.type === 'parent') {
                    self.renderTab(data.action, data);
                }
            });

            $('.tab-content').on('submit', "form[role='subform']", function () {
                $(this).find("button[type='submit']").prop('disabled', true);
                self.submitTab(this.action, $(this).serialize(), $(this).attr('id'));
                return false;
            });

            $('.tab-content').on('click', '.pagination ul li a', function () {
                self.renderTab(this.href, '');
                return false;
            });

            $('.tab-content').on('click', 'table thead tr th a', function () {
                self.renderTab(this.href, '');
                return false;
            });

            $('.tab-content').on('click', '.button-tab', function () {
                self.renderTab(this.href, '');
                return false;
            });

            $('.tab-content').on('focus', 'input, select', function () {
                $(this).parents('form').find("button[type='submit']").prop('disabled', false);
                removeHelpBlock(this);
                return false;
            });
        },

        _getContentElement: function () {
            this.namespace = this.element.find('li.active a:first').attr('href');
            return this.element.find(this.namespace);
        },

        _parentIdExists: function (a) {
            $typeTab = $(a).data('type');
            parentId = $('#tab-parent_id').val();

            if (parentId === '' && $typeTab === 'parent') {
                softcomAlert('', 'Esta aba só pode ser alterada após salvar o cadastro principal.');
                return false;
            }
        },

        _maskElements: function () {
            elements = this.element.find(this.namespace).find($(":input"));
            maskElements(elements);
        },

        renderTab: function (action, data) {
            var self = this,
                baseUrl = $('#baseUrl').data('value');

            $.ajax({
                url:  action.includes(baseUrl) ? action : baseUrl + '/' + action,
                data: data,
                type: 'GET',
                processData: false,
                success: function (response) {
                    self._getContentElement().html(response);
                    self._maskElements();
                }
            });
        },

        submitTab: function (action, data, id) {
            $.ajax({
                type: 'POST',
                url: action,
                data: data,
                dataType: 'json',
                success: function () {
                    $('.modal-backdrop').remove();
                    $('.close').trigger('click');
                    $('.nav-tabs .active a').trigger('click');
                    mensagem('Sucesso', 'Realizado com Sucesso!', 'success');
                },
                error: function (data) {
                    var errors = data.responseJSON;
                    if (errors) {
                        $.each(errors, function (i, message) {
                            addHelpBlock(id, i, message);
                        });

                        mensagem('Aviso', 'Verifique os dados e tente novamente.', 'warning');
                        return false;
                    } else {
                        mensagem('Sucesso', 'Realizado com Sucesso!', 'success');
                        $('.modal-backdrop').remove();
                        $('.close').trigger('click');
                        $('.nav-tabs .active a').trigger('click');
                    }
                }
            });
        }

    });

})(jQuery);
